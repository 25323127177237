import axios from "axios";
import JwtService from "@/common/JwtService";
import store from "@/store";
import router from "@/router";

const BASE_URI = {
  PRODUCTION: "https://kepegawaian.pauddikdasmen.kemdikbud.go.id/service",
  STAGING: "http://118.98.166.56:83/service"
};

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_BASE_URI
      : BASE_URI.STAGING
});

instance.interceptors.request.use(function(config) {
  const token = JwtService.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance.interceptors.response.use(
  function(config) {
    return config;
  },
  function(error) {
    if (error.response && error.response.status == 401) {
      store.commit("auth/purgeAuth");
      const loginpath = window.location.pathname;
      const loginsearch = window.location.search;
      router.replace({
        name: "login",
        query: { pathname: loginpath, search: loginsearch }
      });
      setTimeout(function() {
        store.commit("snackbar/setSnack", {
          show: true,
          message: "Token Expired. Sorry you must login again",
          color: "error"
        });
      }, 500);
    }
    return Promise.reject(error);
  }
);

const BaseInstance = {
  async query(resource, params) {
    return instance.get(resource, params);
  },

  async download(resource, params, type) {
    return instance.get(resource, { params, responseType: type });
  },

  async fetch(resource, slug = "", params) {
    return instance.get(`${resource}/${slug}`, params);
  },

  async post(resource, params, config) {
    return instance.post(`${resource}`, params, config);
  },

  async create(resource, slug = "", params, config) {
    return instance.post(`${resource}/${slug}`, params, config);
  },

  async update(resource, slug, params) {
    return instance.put(`${resource}/${slug}`, params);
  },

  async put(resource, params, config) {
    return instance.put(`${resource}`, params, config);
  },

  async patch(resource, params, config) {
    return instance.patch(`${resource}`, params, config);
  },

  async patchSlug(resource, slug, params) {
    return instance.patch(`${resource}/${slug}`, params);
  },

  async remove(resource, config) {
    return instance.delete(resource, config);
  },

  async deleteSlug(resource, slug, config) {
    return instance.delete(`${resource}/${slug}`, config);
  },

  cancelRequest() {
    return instance.CancelToken;
  }
};

export default BaseInstance;
