// End Point //
// Auth
export const AUTH = `auth`;
export const AUTH_LOGIN = `${AUTH}/login`;
export const AUTH_FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const AUTH_RESET_PASSWORD = `${AUTH}/reset-password`;
export const AUTH_PROFILE = `${AUTH}/profile`;
export const AUTH_CHANGE_PASSWORD = `${AUTH}/change-password`;

// Pegawai
export const PEGAWAI = `pegawai`;
export const PEGAWAI_LIST = `${PEGAWAI}/list`;
export const PEGAWAI_DETAIL = `${PEGAWAI}/detail`;
export const PEGAWAI_HISTORY_ATTENDANCE = `${PEGAWAI}/history-attendance`;
export const PEGAWAI_SCHEDULES = `${PEGAWAI}/schedules`;
export const PEGAWAI_SUMMARY = `${PEGAWAI}/summary`;
export const PEGAWAI_SAVE = `${PEGAWAI}/save`;
export const PEGAWAI_DIGITAL_LIST = `${PEGAWAI}/digital-data/list`;
export const PEGAWAI_DIGITAL_SAVE = `${PEGAWAI}/digital-data/save`;
export const PEGAWAI_DIGITAL_DELETE = `${PEGAWAI}/digital-data/delete`;
export const PEGAWAI_HISTORY = `${PEGAWAI}/history`;
export const PEGAWAI_HISTORY_LIST = `${PEGAWAI}/history/list`;
export const PEGAWAI_HISTORY_DETAIL = `${PEGAWAI}/history-detail`;
export const PEGAWAI_HISTORY_APPROVE = `${PEGAWAI}/history/approve`;
export const PEGAWAI_HISTORY_DECLINE = `${PEGAWAI}/history/decline`;
export const PEGAWAI_RIWAYAT_LIST = `${PEGAWAI}/riwayat`;
export const PEGAWAI_FAMILY_SAVE = `${PEGAWAI}/family-data/save`;
export const PEGAWAI_FAMILY_DELETE = `${PEGAWAI}/family-data/delete`;
export const PEGAWAI_CHECK_NIP = `${PEGAWAI}/checkNIP`;
export const PEGAWAI_CHECK_NIP_V2 = `${PEGAWAI}/cek`;
export const PEGAWAI_LIST_APPROVED = `${PEGAWAI}/listapproved`;
export const PEGAWAI_APPROVED = `${PEGAWAI}/approved`;

// Unit Kerja
export const PUBLIC = `public`;
export const UNIT_KERJA = `unit-kerja`;
export const UNIT_UTAMA = `${PUBLIC}/unit-utama`;
export const UNIT_KERJA_ALL = `${PUBLIC}/${UNIT_KERJA}/all`;
export const UNIT_KERJA_2 = `${PUBLIC}/${UNIT_KERJA}/${UNIT_KERJA}-2`;
export const UNIT_KERJA_3 = `${PUBLIC}/${UNIT_KERJA}/${UNIT_KERJA}-3`;
export const UNIT_KERJA_4 = `${PUBLIC}/${UNIT_KERJA}/${UNIT_KERJA}-4`;
export const IMAGE_GROUP = `${PUBLIC}/image-group`;
export const DIGITAL_LIST = `${PUBLIC}/digital-list`;
export const USER_SEARCH = `${PUBLIC}/user-search`;
export const PENDIDIKAN_LIST = `${PUBLIC}/pendidikan`;
export const STUDI_LIST = `${PUBLIC}/studi`;
export const GOLONGAN_LIST = `${PUBLIC}/golongan`;
export const ESELON_LIST = `${PUBLIC}/eselon`;
export const JABATAN_LIST = `${PUBLIC}/jabatan`;
export const PANGKAT_LIST = `${PUBLIC}/pangkat`;
export const KELAS_LIST = `${PUBLIC}/jabatan-kelas`;
export const PROVINCE_LIST = `${PUBLIC}/province-search`;
export const CITY_LIST = `${PUBLIC}/city-search`;
export const DISTRICT_LIST = `${PUBLIC}/district-search`;
export const VILLAGE_LIST = `${PUBLIC}/village-search`;
export const JENIS_CATATAN_LIST = `${PUBLIC}/jenis-catatan`;

// Schedule Unit
export const SCHEDULE_UNIT = `schedule-unit`;
export const SCHEDULE_UNIT_LIST = `${SCHEDULE_UNIT}/list`;
export const SCHEDULE_UNIT_DETAIL = `${SCHEDULE_UNIT}/detail`;
export const SCHEDULE_UNIT_SAVE = `${SCHEDULE_UNIT}/save`;
export const SCHEDULE_UNIT_DELETE = `${SCHEDULE_UNIT}/delete`;
export const SCHEDULE_UNIT_LIST_ACTIVE = `${SCHEDULE_UNIT}/listactive`;
export const SCHEDULE_UNIT_LIST_BY_UNIT = `${SCHEDULE_UNIT}/listbyunit`;
export const SCHEDULE_UNIT_SET_DEFAULT = `${SCHEDULE_UNIT}/setdefault`;

// Schedule Group
export const SCHEDULE_GROUP = `schedule-group`;
export const SCHEDULE_GROUP_LIST = `${SCHEDULE_GROUP}/list`;
export const SCHEDULE_GROUP_DETAIL = `${SCHEDULE_GROUP}/detail`;
export const SCHEDULE_GROUP_SAVE = `${SCHEDULE_GROUP}/save`;
export const SCHEDULE_GROUP_DELETE = `${SCHEDULE_GROUP}/delete`;
export const SCHEDULE_GROUP_ARCHIVE = `${SCHEDULE_GROUP}/archive`;
export const SCHEDULE_GROUP_RESTORE = `${SCHEDULE_GROUP}/restore`;

// Message
export const MESSAGE = `message`;
export const MESSAGE_LIST = `${MESSAGE}/list`;
export const MESSAGE_DETAIL = `${MESSAGE}/detail`;
export const MESSAGE_SAVE = `${MESSAGE}/save`;
export const MESSAGE_DELETE = `${MESSAGE}/delete`;

// Message Inbox
export const MESSAGE_INBOX = `${MESSAGE}/inbox`;
export const MESSAGE_INBOX_LIST = `${MESSAGE_INBOX}/list`;
export const MESSAGE_INBOX_DETAIL = `${MESSAGE_INBOX}/detail`;
export const MESSAGE_INBOX_DELETE = `${MESSAGE_INBOX}/delete`;

// User
export const USER = `users`;
export const USER_LIST = `${USER}/list`;
export const USER_LIST_ROLE = `${USER}/roles`;
export const USER_DETAIL = `${USER}/detail`;
export const USER_SAVE = `${USER}/save`;
export const USER_DELETE = `${USER}/delete`;

// Holiday
export const HOLIDAY = `holiday`;
export const HOLIDAY_LIST = `${HOLIDAY}/list`;
export const HOLIDAY_DETAIL = `${HOLIDAY}/detail`;
export const HOLIDAY_SAVE = `${HOLIDAY}/save`;
export const HOLIDAY_DELETE = `${HOLIDAY}/delete`;

// News
export const NEWS = `news`;
export const NEWS_LIST = `${NEWS}/list`;
export const NEWS_DETAIL = `${NEWS}/detail`;
export const NEWS_SAVE = `${NEWS}/save`;
export const NEWS_DELETE = `${NEWS}/delete`;

// Attendance
export const ATTENDANCE = `attendance`;
export const ATTENDANCE_LIST = `${ATTENDANCE}/list`;
export const ATTENDANCE_FORCE_UPDATE = `${ATTENDANCE}/force-update`;
export const ATTENDANCE_REQUEST_UPDATE = `${ATTENDANCE}/request-update`;
export const ATTENDANCE_REQUEST_UPDATE_WEB = `${ATTENDANCE}/request-updateweb`;
export const RECAPITULATION_LIST = `${ATTENDANCE}/recapitulation/list`;
export const ATTENDANCE_REQUEST_UPDATE_LIST = `${ATTENDANCE_REQUEST_UPDATE}/list`;
export const ATTENDANCE_REQUEST_UPDATE_BULK = `${ATTENDANCE_REQUEST_UPDATE}-bulk`;
export const ATTENDANCE_REQUEST_UPDATE_STATUS = `${ATTENDANCE_REQUEST_UPDATE}-status`;
export const ATTENDANCE_REQUEST_UPDATE_DELETE = `${ATTENDANCE_REQUEST_UPDATE}-delete`;
export const ATTENDANCE_REQUEST_OFF = `${ATTENDANCE}/request-off`;
export const ATTENDANCE_REQUEST_OFF_LIST = `${ATTENDANCE_REQUEST_OFF}/list`;
export const ATTENDANCE_REQUEST_OFF_BULK = `${ATTENDANCE_REQUEST_OFF}-bulk`;
export const ATTENDANCE_REQUEST_OFF_STATUS = `${ATTENDANCE_REQUEST_OFF}-status`;
export const ATTENDANCE_REQUEST_OFF_DELETE = `${ATTENDANCE_REQUEST_OFF}-delete`;
export const ATTENDANCE_CHECK_IN_WEB = `${ATTENDANCE}/checkinweb`;
export const ATTENDANCE_CHECK_OUT_WEB = `${ATTENDANCE}/checkoutweb`;

// Attendance Manual
export const ATTENDANCE_MANUAL = `attendance-manual`;
export const ATTENDANCE_MANUAL_LIST = `${ATTENDANCE_MANUAL}/list`;
export const ATTENDANCE_MANUAL_DETAIL = `${ATTENDANCE_MANUAL}/detail`;
export const ATTENDANCE_MANUAL_SAVE = `${ATTENDANCE_MANUAL}/save`;
export const ATTENDANCE_MANUAL_DELETE = `${ATTENDANCE_MANUAL}/delete`;

// Notification
export const NOTIFICATION = `notification`;
export const NOTIFICATION_LIST = `${NOTIFICATION}/list`;

// Nomenklatur
export const NOMENKLATUR = `nomenklatur`;
export const NOMENKLATUR_LIST = `${NOMENKLATUR}/list`;
export const NOMENKLATUR_UPDATE = `${NOMENKLATUR}/update`;

// Nomenklatur
export const REPORT = `report`;
export const REPORT_DICIPLINE = `${REPORT}/discipline`;
export const REPORT_RECAPITULATION = `${REPORT}/recapitulation`;
export const REPORT_LEAVE = `${REPORT}/leave`;
export const REPORT_LAMPIRAN1G = `${REPORT}/lampiran1g`;
export const REPORT_REKAPITULASI = `${REPORT}/rekapitulasi`;
export const REPORT_REKAP_CUTI = `${REPORT}/rekapcuti`;
export const REPORT_DETAIL_KEHADIRAN = `${REPORT}/detailkehadiran`;

export const REPORT_LAPORAN1G = `${REPORT}/laporan1g`;
export const REPORT_LAPORAN1G_DATA = `${REPORT_LAPORAN1G}/data`;
export const REPORT_LAPORAN1G_EXCEL = `${REPORT_LAPORAN1G}/excel`;

// SKP
export const SKP = `skp`;
export const SKP_LIST = `${SKP}/list`;

// Data Utama
export const DATA_UTAMA = `datautama`;
export const DATA_UTAMA_GOLONGAN = `${DATA_UTAMA}/golongan`;
export const DATA_UTAMA_GOLONGAN_LIST = `${DATA_UTAMA_GOLONGAN}/list`;
export const DATA_UTAMA_GOLONGAN_DETAIL = `${DATA_UTAMA_GOLONGAN}/detail`;
export const DATA_UTAMA_GOLONGAN_SAVE = `${DATA_UTAMA_GOLONGAN}/save`;
export const DATA_UTAMA_GOLONGAN_DELETE = `${DATA_UTAMA_GOLONGAN}/delete`;
export const DATA_UTAMA_GOLONGAN_ALL = `${DATA_UTAMA_GOLONGAN}/all`;
export const DATA_UTAMA_KEPANGKATAN = `${DATA_UTAMA}/kepangkatan`;
export const DATA_UTAMA_KEPANGKATAN_LIST = `${DATA_UTAMA_KEPANGKATAN}/list`;
export const DATA_UTAMA_KEPANGKATAN_DETAIL = `${DATA_UTAMA_KEPANGKATAN}/detail`;
export const DATA_UTAMA_KEPANGKATAN_SAVE = `${DATA_UTAMA_KEPANGKATAN}/save`;
export const DATA_UTAMA_KEPANGKATAN_DELETE = `${DATA_UTAMA_KEPANGKATAN}/delete`;
export const DATA_UTAMA_KEPANGKATAN_LIST_BY_GOL = `${DATA_UTAMA_KEPANGKATAN}/listbygol`;
export const DATA_UTAMA_ESELON = `${DATA_UTAMA}/eselon`;
export const DATA_UTAMA_ESELON_LIST = `${DATA_UTAMA_ESELON}/list`;
export const DATA_UTAMA_ESELON_DETAIL = `${DATA_UTAMA_ESELON}/detail`;
export const DATA_UTAMA_ESELON_SAVE = `${DATA_UTAMA_ESELON}/save`;
export const DATA_UTAMA_ESELON_DELETE = `${DATA_UTAMA_ESELON}/delete`;
export const DATA_UTAMA_ESELON_ALL = `${DATA_UTAMA_ESELON}/all`;
export const DATA_UTAMA_ESELON_LIST_BY_JABATAN = `${DATA_UTAMA_ESELON}/listbyjabatan`;
export const DATA_UTAMA_PENDIDIKAN = `${DATA_UTAMA}/pendidikan`;
export const DATA_UTAMA_PENDIDIKAN_LIST = `${DATA_UTAMA_PENDIDIKAN}/list`;
export const DATA_UTAMA_PENDIDIKAN_DETAIL = `${DATA_UTAMA_PENDIDIKAN}/detail`;
export const DATA_UTAMA_PENDIDIKAN_SAVE = `${DATA_UTAMA_PENDIDIKAN}/save`;
export const DATA_UTAMA_PENDIDIKAN_DELETE = `${DATA_UTAMA_PENDIDIKAN}/delete`;
export const DATA_UTAMA_PENDIDIKAN_LEVEL_ALL = `${DATA_UTAMA_PENDIDIKAN}/listpendidikanlevelall`;
export const DATA_UTAMA_PENDIDIKAN_LEVEL_LIST = `${DATA_UTAMA_PENDIDIKAN}/listpendidikanlevel`;
export const DATA_UTAMA_PENDIDIKAN_LEVEL_DETAIL = `${DATA_UTAMA_PENDIDIKAN}/detailpendidikanlevel`;
export const DATA_UTAMA_PENDIDIKAN_LEVEL_SAVE = `${DATA_UTAMA_PENDIDIKAN}/savependidikanlevel`;
export const DATA_UTAMA_PENDIDIKAN_LEVEL_DELETE = `${DATA_UTAMA_PENDIDIKAN}/deletependidikanlevel`;
export const DATA_UTAMA_PENDIDIKAN_STUDI_LIST = `${DATA_UTAMA_PENDIDIKAN}/liststudi`;
export const DATA_UTAMA_PENDIDIKAN_STUDI_DETAIL = `${DATA_UTAMA_PENDIDIKAN}/detailstudi`;
export const DATA_UTAMA_PENDIDIKAN_STUDI_SAVE = `${DATA_UTAMA_PENDIDIKAN}/savestudi`;
export const DATA_UTAMA_PENDIDIKAN_STUDI_DELETE = `${DATA_UTAMA_PENDIDIKAN}/deletestudi`;
export const DATA_UTAMA_PENDIDIKAN_RUMPUN_LIST = `${DATA_UTAMA_PENDIDIKAN}/listrumpun`;
export const DATA_UTAMA_PENDIDIKAN_RUMPUN_DETAIL = `${DATA_UTAMA_PENDIDIKAN}/detailrumpun`;
export const DATA_UTAMA_PENDIDIKAN_RUMPUN_SAVE = `${DATA_UTAMA_PENDIDIKAN}/saverumpun`;
export const DATA_UTAMA_PENDIDIKAN_RUMPUN_DELETE = `${DATA_UTAMA_PENDIDIKAN}/deleterumpun`;
export const DATA_UTAMA_UNIT_KERJA = `${DATA_UTAMA}/unitkerja`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_LOV = `${DATA_UTAMA_UNIT_KERJA}/UnitKerjaGroup`;
export const DATA_UTAMA_UNIT_KERJA_ALL = `${DATA_UTAMA_UNIT_KERJA}/UnitKerjaAll`;

// Unit Utama
export const DATA_UTAMA_UNIT_KERJA_UTAMA = `${DATA_UTAMA_UNIT_KERJA}/utama`;
export const DATA_UTAMA_UNIT_KERJA_UTAMA_LIST = `${DATA_UTAMA_UNIT_KERJA_UTAMA}/list`;
export const DATA_UTAMA_UNIT_KERJA_UTAMA_DETAIL = `${DATA_UTAMA_UNIT_KERJA_UTAMA}/detail`;
export const DATA_UTAMA_UNIT_KERJA_UTAMA_SAVE = `${DATA_UTAMA_UNIT_KERJA_UTAMA}/save`;
export const DATA_UTAMA_UNIT_KERJA_UTAMA_DELETE = `${DATA_UTAMA_UNIT_KERJA_UTAMA}/delete`;
export const DATA_UTAMA_UNIT_KERJA_UTAMA_IMPORT = `${DATA_UTAMA_UNIT_KERJA_UTAMA}/import`;
export const DATA_UTAMA_UNIT_KERJA_UTAMA_EXPORT = `${DATA_UTAMA_UNIT_KERJA_UTAMA}/export`;

// Unit Group
export const DATA_UTAMA_UNIT_KERJA_GROUP = `${DATA_UTAMA_UNIT_KERJA}/kerjagroup`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_LIST = `${DATA_UTAMA_UNIT_KERJA_GROUP}/list`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_DETAIL = `${DATA_UTAMA_UNIT_KERJA_GROUP}/detail`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_SAVE = `${DATA_UTAMA_UNIT_KERJA_GROUP}/save`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_DELETE = `${DATA_UTAMA_UNIT_KERJA_GROUP}/delete`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_IMPORT = `${DATA_UTAMA_UNIT_KERJA_GROUP}/import`;
export const DATA_UTAMA_UNIT_KERJA_GROUP_EXPORT = `${DATA_UTAMA_UNIT_KERJA_GROUP}/export`;

// Unit Kerja 2
export const DATA_UTAMA_UNIT_KERJA_2 = `${DATA_UTAMA_UNIT_KERJA}/kerja2`;
export const DATA_UTAMA_UNIT_KERJA_2_LIST = `${DATA_UTAMA_UNIT_KERJA_2}/list`;
export const DATA_UTAMA_UNIT_KERJA_2_DETAIL = `${DATA_UTAMA_UNIT_KERJA_2}/detail`;
export const DATA_UTAMA_UNIT_KERJA_2_SAVE = `${DATA_UTAMA_UNIT_KERJA_2}/save`;
export const DATA_UTAMA_UNIT_KERJA_2_DELETE = `${DATA_UTAMA_UNIT_KERJA_2}/delete`;
export const DATA_UTAMA_UNIT_KERJA_2_IMPORT = `${DATA_UTAMA_UNIT_KERJA_2}/import`;
export const DATA_UTAMA_UNIT_KERJA_2_EXPORT = `${DATA_UTAMA_UNIT_KERJA_2}/export`;

// Unit Kerja 3
export const DATA_UTAMA_UNIT_KERJA_3 = `${DATA_UTAMA_UNIT_KERJA}/kerja3`;
export const DATA_UTAMA_UNIT_KERJA_3_LIST = `${DATA_UTAMA_UNIT_KERJA_3}/list`;
export const DATA_UTAMA_UNIT_KERJA_3_DETAIL = `${DATA_UTAMA_UNIT_KERJA_3}/detail`;
export const DATA_UTAMA_UNIT_KERJA_3_SAVE = `${DATA_UTAMA_UNIT_KERJA_3}/save`;
export const DATA_UTAMA_UNIT_KERJA_3_DELETE = `${DATA_UTAMA_UNIT_KERJA_3}/delete`;
export const DATA_UTAMA_UNIT_KERJA_3_IMPORT = `${DATA_UTAMA_UNIT_KERJA_3}/import`;
export const DATA_UTAMA_UNIT_KERJA_3_EXPORT = `${DATA_UTAMA_UNIT_KERJA_3}/export`;

// Unit Kerja 4
export const DATA_UTAMA_UNIT_KERJA_4 = `${DATA_UTAMA_UNIT_KERJA}/kerja4`;
export const DATA_UTAMA_UNIT_KERJA_4_LIST = `${DATA_UTAMA_UNIT_KERJA_4}/list`;
export const DATA_UTAMA_UNIT_KERJA_4_DETAIL = `${DATA_UTAMA_UNIT_KERJA_4}/detail`;
export const DATA_UTAMA_UNIT_KERJA_4_SAVE = `${DATA_UTAMA_UNIT_KERJA_4}/save`;
export const DATA_UTAMA_UNIT_KERJA_4_DELETE = `${DATA_UTAMA_UNIT_KERJA_4}/delete`;
export const DATA_UTAMA_UNIT_KERJA_4_IMPORT = `${DATA_UTAMA_UNIT_KERJA_4}/import`;
export const DATA_UTAMA_UNIT_KERJA_4_EXPORT = `${DATA_UTAMA_UNIT_KERJA_4}/export`;

// Kelas Jabatan
export const DATA_UTAMA_KELAS_JABATAN = `${DATA_UTAMA}/kelas-jabatan`;
export const DATA_UTAMA_KELAS_JABATAN_LIST = `${DATA_UTAMA_KELAS_JABATAN}/list`;
export const DATA_UTAMA_KELAS_JABATAN_DETAIL = `${DATA_UTAMA_KELAS_JABATAN}/detail`;
export const DATA_UTAMA_KELAS_JABATAN_SAVE = `${DATA_UTAMA_KELAS_JABATAN}/save`;
export const DATA_UTAMA_KELAS_JABATAN_DELETE = `${DATA_UTAMA_KELAS_JABATAN}/delete`;
export const DATA_UTAMA_KELAS_JABATAN_ALL = `${DATA_UTAMA_KELAS_JABATAN}/all`;

// Jabatan
export const DATA_UTAMA_JABATAN = `${DATA_UTAMA}/jabatan`;
export const DATA_UTAMA_JABATAN_LIST = `${DATA_UTAMA_JABATAN}/list`;
export const DATA_UTAMA_JABATAN_DETAIL = `${DATA_UTAMA_JABATAN}/detail`;
export const DATA_UTAMA_JABATAN_SAVE = `${DATA_UTAMA_JABATAN}/save`;
export const DATA_UTAMA_JABATAN_DELETE = `${DATA_UTAMA_JABATAN}/delete`;
export const DATA_UTAMA_JABATAN_ALL = `${DATA_UTAMA_JABATAN}/all`;
export const DATA_UTAMA_JABATAN_LIST_KATEGORI = `${DATA_UTAMA_JABATAN}/listkategori`;
export const DATA_UTAMA_JABATAN_KATEGORI = `${DATA_UTAMA_JABATAN}/kategori`;

// Formasi Jabatan
export const DATA_UTAMA_FORMASI_JABATAN = `${DATA_UTAMA}/formasi-jabatan`;
export const DATA_UTAMA_FORMASI_JABATAN_LIST = `${DATA_UTAMA_FORMASI_JABATAN}/list`;
export const DATA_UTAMA_FORMASI_JABATAN_DETAIL = `${DATA_UTAMA_FORMASI_JABATAN}/detail`;
export const DATA_UTAMA_FORMASI_JABATAN_SAVE = `${DATA_UTAMA_FORMASI_JABATAN}/save`;
export const DATA_UTAMA_FORMASI_JABATAN_DELETE = `${DATA_UTAMA_FORMASI_JABATAN}/delete`;
export const DATA_UTAMA_FORMASI_JABATAN_PERSEDIAAN = `${DATA_UTAMA_FORMASI_JABATAN}/persediaan`;
export const DATA_UTAMA_FORMASI_JABATAN_EXPORT = `${DATA_UTAMA_FORMASI_JABATAN}/export`;
export const DATA_UTAMA_FORMASI_JABATAN_IMPORT = `${DATA_UTAMA_FORMASI_JABATAN}/import`;

// Kelompok Jabatan
export const DATA_UTAMA_KELOMPOK_JABATAN = `${DATA_UTAMA}/kelompok-jabatan`;
export const DATA_UTAMA_KELOMPOK_JABATAN_LIST = `${DATA_UTAMA_KELOMPOK_JABATAN}/list`;
export const DATA_UTAMA_KELOMPOK_JABATAN_DETAIL = `${DATA_UTAMA_KELOMPOK_JABATAN}/detail`;
export const DATA_UTAMA_KELOMPOK_JABATAN_SAVE = `${DATA_UTAMA_KELOMPOK_JABATAN}/save`;
export const DATA_UTAMA_KELOMPOK_JABATAN_DELETE = `${DATA_UTAMA_KELOMPOK_JABATAN}/delete`;
export const DATA_UTAMA_KELOMPOK_JABATAN_ALL = `${DATA_UTAMA_KELOMPOK_JABATAN}/all`;

// Lokasi
export const DATA_UTAMA_LOKASI = `${DATA_UTAMA}/lokasi`;
export const DATA_UTAMA_LOKASI_LIST = `${DATA_UTAMA_LOKASI}/list`;
export const DATA_UTAMA_LOKASI_CREATE = `${DATA_UTAMA_LOKASI}/create`;
export const DATA_UTAMA_LOKASI_UPDATE = `${DATA_UTAMA_LOKASI}/update`;
export const DATA_UTAMA_LOKASI_DELETE = `${DATA_UTAMA_LOKASI}/delete`;

// Presensi
export const PRESENSI = `presensi`;
export const PRESENSI_DATA_PRESENSI = `${PRESENSI}/DataPresensi`;
export const PRESENSI_DATA_SCHEDULE_DETAIL = `${PRESENSI}/DataScheduleDetail`;
export const PRESENSI_ABSENSI_WEB = `${PRESENSI}/AbsensiWeb`;
export const PRESENSI_DATA_PROFILE = `${PRESENSI}/DataProfile`;

export const PRESENSI_SCHEDULE_PERIOD = `${PRESENSI}/schedule-period`;
export const PRESENSI_SCHEDULE_PERIOD_LIST = `${PRESENSI_SCHEDULE_PERIOD}/list`;
export const PRESENSI_SCHEDULE_PERIOD_DETAIL = `${PRESENSI_SCHEDULE_PERIOD}/detail`;
export const PRESENSI_SCHEDULE_PERIOD_SAVE = `${PRESENSI_SCHEDULE_PERIOD}/save`;
export const PRESENSI_SCHEDULE_PERIOD_DELETE = `${PRESENSI_SCHEDULE_PERIOD}/delete`;

export const PRESENSI_SCHEDULE_MANUAL = `${PRESENSI}/schedule-manual`;
export const PRESENSI_SCHEDULE_MANUAL_LIST = `${PRESENSI_SCHEDULE_MANUAL}/list`;
export const PRESENSI_SCHEDULE_MANUAL_DETAIL = `${PRESENSI_SCHEDULE_MANUAL}/detail`;
export const PRESENSI_SCHEDULE_MANUAL_SAVE = `${PRESENSI_SCHEDULE_MANUAL}/save`;
export const PRESENSI_SCHEDULE_MANUAL_DELETE = `${PRESENSI_SCHEDULE_MANUAL}/delete`;

export const PRESENSI_ATTENDANCE = `${PRESENSI}/attendance`;
export const PRESENSI_ATTENDANCE_DAFTAR_SCHEDULE = `${PRESENSI_ATTENDANCE}/daftarschedule`;
export const PRESENSI_ATTENDANCE_GENERATE_ATTENDANCE = `${PRESENSI_ATTENDANCE}/generateattendance`;

export const PRESENSI_SCHEDULE_UNIT = `${PRESENSI}/schedule-unit`;
export const PRESENSI_SCHEDULE_UNIT_LIST = `${PRESENSI_SCHEDULE_UNIT}/list`;
export const PRESENSI_SCHEDULE_UNIT_SAVE = `${PRESENSI_SCHEDULE_UNIT}/save`;
export const PRESENSI_SCHEDULE_UNIT_SETDEFAULT = `${PRESENSI_SCHEDULE_UNIT}/setdefault`;
export const PRESENSI_SCHEDULE_UNIT_SETDEFAULT_WFH = `${PRESENSI_SCHEDULE_UNIT}/setdefaultwfh`;
export const PRESENSI_SCHEDULE_UNIT_ACTIVE = `${PRESENSI_SCHEDULE_UNIT}/active`;

export const PRESENSI_SCHEDULE_HOLIDAY = `${PRESENSI}/schedule-holiday`;
export const PRESENSI_SCHEDULE_HOLIDAY_LIST = `${PRESENSI_SCHEDULE_HOLIDAY}/list`;
export const PRESENSI_SCHEDULE_HOLIDAY_DETAIL = `${PRESENSI_SCHEDULE_HOLIDAY}/detail`;
export const PRESENSI_SCHEDULE_HOLIDAY_SAVE = `${PRESENSI_SCHEDULE_HOLIDAY}/save`;
export const PRESENSI_SCHEDULE_HOLIDAY_DELETE = `${PRESENSI_SCHEDULE_HOLIDAY}/delete`;

export const PRESENSI_SKP = `${PRESENSI}/skp`;
export const PRESENSI_SKP_LIST_DATA = `${PRESENSI_SKP}/ListData`;
export const PRESENSI_SKP_SYNCHRON_DATA = `${PRESENSI_SKP}/SynchronData`;
export const PRESENSI_SKP_SYNCHRON_DATA_BULK = `${PRESENSI_SKP}/SynchronData1`;

export const PRESENSI_LOG_ABSENSI = `${PRESENSI}/LogAbsensiByNIP`;
export const PRESENSI_SAVE_ACTIVITY = `${PRESENSI}/SaveActivity`;

export const PRESENSI_LIST_SKIP = `${PRESENSI}/ListSkip`;
export const PRESENSI_SAVE_SKIP = `${PRESENSI}/SaveSkip`;
export const PRESENSI_DELETE_SKIP = `${PRESENSI}/DeleteSkip`;

export const PRESENSI_PEGAWAI_ACTIVE = `${PRESENSI}/PegawaiActive`;

export const PRESENSI_NOTE = `${PRESENSI}/note`;
export const PRESENSI_NOTE_LIST = `${PRESENSI_NOTE}/ListNote`;
export const PRESENSI_NOTE_DETAIL_DATA = `${PRESENSI_NOTE}/DetailData`;
export const PRESENSI_NOTE_SAVE_DATA = `${PRESENSI_NOTE}/SaveData`;
export const PRESENSI_NOTE_DELETE_DATA = `${PRESENSI_NOTE}/DeleteData`;
export const PRESENSI_NOTE_LIST_REAL = `${PRESENSI_NOTE}/ListReal`;
export const PRESENSI_NOTE_LIST_PENDING = `${PRESENSI_NOTE}/ListNotePending`;
export const PRESENSI_NOTE_LIST_HISTORY = `${PRESENSI_NOTE}/ListNoteHistory`;
export const PRESENSI_NOTE_UPDATE_STATUS = `${PRESENSI_NOTE}/ListNoteUpdateStatus`;

// Simpeg
export const SIMPEG = `simpeg`;
export const SIMPEG_MUTASI = `${SIMPEG}/mutasi`;
export const SIMPEG_MUTASI_JABATAN_UNIT = `${SIMPEG_MUTASI}/jabatanunit`;

export const SIMPEG_PEGAWAI = `${SIMPEG}/pegawai`;
export const SIMPEG_PEGAWAI_SAVE = `${SIMPEG_PEGAWAI}/save`;
export const SIMPEG_PEGAWAI_LIST_PENGAJUAN = `${SIMPEG_PEGAWAI}/listpengajuan`;
export const SIMPEG_PEGAWAI_DETAIL_PENGAJUAN = `${SIMPEG_PEGAWAI}/detailpengajuan`;
export const SIMPEG_PEGAWAI_VALIDATION = `${SIMPEG_PEGAWAI}/validation`;
export const SIMPEG_PEGAWAI_APPROVAL = `${SIMPEG_PEGAWAI}/approvel`;

export const SIMPEG_DATA_KELUARGA = `${SIMPEG}/datakeluarga`;
export const SIMPEG_DATA_KELUARGA_LIST = `${SIMPEG_DATA_KELUARGA}/list`;
export const SIMPEG_DATA_KELUARGA_SAVE = `${SIMPEG_DATA_KELUARGA}/save`;
export const SIMPEG_DATA_KELUARGA_LIST_PENGAJUAN = `${SIMPEG_DATA_KELUARGA}/listpengajuan`;
export const SIMPEG_DATA_KELUARGA_DETAIL_PENGAJUAN = `${SIMPEG_DATA_KELUARGA}/detailpengajuan`;
export const SIMPEG_DATA_KELUARGA_VALIDATION = `${SIMPEG_DATA_KELUARGA}/validation`;
export const SIMPEG_DATA_KELUARGA_APPROVAL = `${SIMPEG_DATA_KELUARGA}/approvel`;

export const SIMPEG_PENGAJUAN = `${SIMPEG}/pengajuan`;
export const SIMPEG_PENGAJUAN_LIST_PENDING = `${SIMPEG_PENGAJUAN}/listpending`;
export const SIMPEG_PENGAJUAN_DETAIL_PENDING = `${SIMPEG_PENGAJUAN}/detailpending`;
export const SIMPEG_PENGAJUAN_APPROVAL_MUTASI = `${SIMPEG_PENGAJUAN}/approvelmutasi`;

// Laporan
export const SIMPEG_LAPORAN = `${SIMPEG}/laporan`;
export const SIMPEG_LAPORAN_DUK = `${SIMPEG_LAPORAN}/duk`;
export const SIMPEG_LAPORAN_NOMINATIF = `${SIMPEG_LAPORAN}/nominatif`;
export const SIMPEG_LAPORAN_NAIK_PANGKAT = `${SIMPEG_LAPORAN}/naikPangkat`;
export const SIMPEG_LAPORAN_PENSIUN = `${SIMPEG_LAPORAN}/pensiun`;
export const SIMPEG_LAPORAN_KGB = `${SIMPEG_LAPORAN}/kgb`;
export const SIMPEG_LAPORAN_DUK_EXPORT = `${SIMPEG_LAPORAN}/duk-d`;
export const SIMPEG_LAPORAN_NOMINATIF_EXPORT = `${SIMPEG_LAPORAN}/nominatif-d`;
export const SIMPEG_LAPORAN_NAIK_PANGKAT_EXPORT = `${SIMPEG_LAPORAN}/naikPangkat-d`;
export const SIMPEG_LAPORAN_PENSIUN_EXPORT = `${SIMPEG_LAPORAN}/pensiun-d`;
export const SIMPEG_LAPORAN_KGB_EXPORT = `${SIMPEG_LAPORAN}/kgb-d`;

// Rekapitulasi
export const SIMPEG_REKAPITULASI = `${SIMPEG}/rekapitulasi`;
export const SIMPEG_REKAPITULASI_NAIK_PANGKAT = `${SIMPEG_REKAPITULASI}/naikPangkat`;
export const SIMPEG_REKAPITULASI_MASA_KERJA = `${SIMPEG_REKAPITULASI}/masaKerja`;
export const SIMPEG_REKAPITULASI_PENDIDIKAN = `${SIMPEG_REKAPITULASI}/pendidikan`;
export const SIMPEG_REKAPITULASI_KELAMIN = `${SIMPEG_REKAPITULASI}/kelamin`;
export const SIMPEG_REKAPITULASI_GOLONGAN = `${SIMPEG_REKAPITULASI}/golongan`;
export const SIMPEG_REKAPITULASI_KEBUTUHAN_PEGAWAI = `${SIMPEG_REKAPITULASI}/kebutuhanPegawai`;
export const SIMPEG_REKAPITULASI_ESELON = `${SIMPEG_REKAPITULASI}/eselon`;
export const SIMPEG_REKAPITULASI_NAIK_PANGKAT_EXPORT = `${SIMPEG_REKAPITULASI}/naikPangkat-d`;
export const SIMPEG_REKAPITULASI_MASA_KERJA_EXPORT = `${SIMPEG_REKAPITULASI}/masaKerja-d`;
export const SIMPEG_REKAPITULASI_PENDIDIKAN_EXPORT = `${SIMPEG_REKAPITULASI}/pendidikan-d`;
export const SIMPEG_REKAPITULASI_KELAMIN_EXPORT = `${SIMPEG_REKAPITULASI}/kelamin-d`;
export const SIMPEG_REKAPITULASI_GOLONGAN_EXPORT = `${SIMPEG_REKAPITULASI}/golongan-d`;
export const SIMPEG_REKAPITULASI_KEBUTUHAN_PEGAWAI_EXPORT = `${SIMPEG_REKAPITULASI}/kebutuhanPegawai-d`;
export const SIMPEG_REKAPITULASI_ESELON_EXPORT = `${SIMPEG_REKAPITULASI}/eselon-d`;

// Machine
export const PRESENSI_MACHINE = `${PRESENSI}/machine`;
export const PRESENSI_MACHINE_DATA_NIP = `${PRESENSI_MACHINE}/data-nip`;
export const PRESENSI_MACHINE_DATA_NIP_LIST = `${PRESENSI_MACHINE_DATA_NIP}/list`;
export const PRESENSI_MACHINE_DATA_NIP_DETAIL = `${PRESENSI_MACHINE_DATA_NIP}/detail`;
export const PRESENSI_MACHINE_DATA_NIP_SAVE = `${PRESENSI_MACHINE_DATA_NIP}/save`;
export const PRESENSI_MACHINE_DATA_NIP_DELETE = `${PRESENSI_MACHINE_DATA_NIP}/delete`;
export const PRESENSI_MACHINE_DEVICE = `${PRESENSI_MACHINE}/device`;
export const PRESENSI_MACHINE_DEVICE_LIST = `${PRESENSI_MACHINE_DEVICE}/list`;
export const PRESENSI_MACHINE_DEVICE_DETAIL = `${PRESENSI_MACHINE_DEVICE}/detail`;
export const PRESENSI_MACHINE_DEVICE_SAVE = `${PRESENSI_MACHINE_DEVICE}/save`;
export const PRESENSI_MACHINE_DEVICE_DELETE = `${PRESENSI_MACHINE_DEVICE}/delete`;
export const PRESENSI_MACHINE_DEVICE_STATUS = `${PRESENSI_MACHINE_DEVICE}/status`;
export const PRESENSI_MACHINE_DEVICE_MANUAL_SYNCHRON = `${PRESENSI_MACHINE_DEVICE}/manual-synchron`;
export const PRESENSI_MACHINE_DEVICE_ABSENSI_LOG = `${PRESENSI_MACHINE_DEVICE}/absensilog`;
export const PRESENSI_MACHINE_DEVICE_SYNC_LOG = `${PRESENSI_MACHINE_DEVICE}/synclog`;

// Group NIP
export const PRESENSI_GROUP_NIP = `${PRESENSI}/GroupNIP`;
export const PRESENSI_GROUP_NIP_LIST = `${PRESENSI_GROUP_NIP}/list`;
export const PRESENSI_GROUP_NIP_DETAIL = `${PRESENSI_GROUP_NIP}/detail`;
export const PRESENSI_GROUP_NIP_SAVE = `${PRESENSI_GROUP_NIP}/save`;
export const PRESENSI_GROUP_NIP_DELETE = `${PRESENSI_GROUP_NIP}/delete`;
export const PRESENSI_GROUP_NIP_LIST_ACTIVE = `${PRESENSI_GROUP_NIP}/list-active`;

// Config
export const CONFIG = `${PRESENSI}/config`;
export const CONFIG_PERSEN_TUKIN = `${CONFIG}/persentukin`;
export const CONFIG_PERSEN_TUKIN_LIST = `${CONFIG_PERSEN_TUKIN}/list`;
export const CONFIG_PERSEN_TUKIN_DETAIL = `${CONFIG_PERSEN_TUKIN}/detail`;
export const CONFIG_PERSEN_TUKIN_SAVE = `${CONFIG_PERSEN_TUKIN}/save`;

// Dashboard
export const DASHBOARD = `dashboard`;
export const DASHBOARD_SUMMARY_CURRENT = `${DASHBOARD}/summary-current`;
export const DASHBOARD_PRESENT_LIST = `${DASHBOARD}/present-list`;
export const DASHBOARD_BIRTHDAY_LIST = `${DASHBOARD}/birthday-list`;
export const DASHBOARD_GRAFIK1 = `${DASHBOARD}/Grafik1`;
export const DASHBOARD_GRAFIK2 = `${DASHBOARD}/Grafik2`;
export const DASHBOARD_GRAFIK3 = `${DASHBOARD}/Grafik3`;
export const DASHBOARD_LIST_R_FILTER = `${DASHBOARD}/ListRFilter`;
export const DASHBOARD_REKAPITULASI = `${DASHBOARD}/Rekapitulasi`;
export const DASHBOARD_COUNT_PEGAWAI = `${DASHBOARD}/CountPegawai`;
export const DASHBOARD_SIMPEG = `${DASHBOARD}/Simpeg`;
