import BaseInstance from "../base.instance";
import {
  DATA_UTAMA_GOLONGAN_LIST,
  DATA_UTAMA_GOLONGAN_DETAIL,
  DATA_UTAMA_GOLONGAN_SAVE,
  DATA_UTAMA_GOLONGAN_DELETE,
  DATA_UTAMA_GOLONGAN_ALL,
  DATA_UTAMA_KEPANGKATAN_LIST,
  DATA_UTAMA_KEPANGKATAN_DETAIL,
  DATA_UTAMA_KEPANGKATAN_SAVE,
  DATA_UTAMA_KEPANGKATAN_DELETE,
  DATA_UTAMA_KEPANGKATAN_LIST_BY_GOL,
  DATA_UTAMA_ESELON_LIST,
  DATA_UTAMA_ESELON_DETAIL,
  DATA_UTAMA_ESELON_SAVE,
  DATA_UTAMA_ESELON_DELETE,
  DATA_UTAMA_ESELON_ALL,
  DATA_UTAMA_ESELON_LIST_BY_JABATAN,
  DATA_UTAMA_PENDIDIKAN_LIST,
  DATA_UTAMA_PENDIDIKAN_DETAIL,
  DATA_UTAMA_PENDIDIKAN_SAVE,
  DATA_UTAMA_PENDIDIKAN_DELETE,
  DATA_UTAMA_PENDIDIKAN_STUDI_LIST,
  DATA_UTAMA_PENDIDIKAN_STUDI_DETAIL,
  DATA_UTAMA_PENDIDIKAN_STUDI_SAVE,
  DATA_UTAMA_PENDIDIKAN_STUDI_DELETE,
  DATA_UTAMA_PENDIDIKAN_LEVEL_LIST,
  DATA_UTAMA_PENDIDIKAN_LEVEL_DETAIL,
  DATA_UTAMA_PENDIDIKAN_LEVEL_SAVE,
  DATA_UTAMA_PENDIDIKAN_LEVEL_DELETE,
  DATA_UTAMA_PENDIDIKAN_LEVEL_ALL,
  DATA_UTAMA_PENDIDIKAN_RUMPUN_LIST,
  DATA_UTAMA_PENDIDIKAN_RUMPUN_DETAIL,
  DATA_UTAMA_PENDIDIKAN_RUMPUN_SAVE,
  DATA_UTAMA_PENDIDIKAN_RUMPUN_DELETE,
  DATA_UTAMA_UNIT_KERJA_GROUP_LOV,
  DATA_UTAMA_UNIT_KERJA_ALL,
  DATA_UTAMA_UNIT_KERJA_UTAMA_LIST,
  DATA_UTAMA_UNIT_KERJA_UTAMA_DETAIL,
  DATA_UTAMA_UNIT_KERJA_UTAMA_SAVE,
  DATA_UTAMA_UNIT_KERJA_UTAMA_DELETE,
  DATA_UTAMA_UNIT_KERJA_UTAMA_IMPORT,
  DATA_UTAMA_UNIT_KERJA_UTAMA_EXPORT,
  DATA_UTAMA_UNIT_KERJA_GROUP_LIST,
  DATA_UTAMA_UNIT_KERJA_GROUP_DETAIL,
  DATA_UTAMA_UNIT_KERJA_GROUP_SAVE,
  DATA_UTAMA_UNIT_KERJA_GROUP_DELETE,
  DATA_UTAMA_UNIT_KERJA_GROUP_IMPORT,
  DATA_UTAMA_UNIT_KERJA_GROUP_EXPORT,
  DATA_UTAMA_UNIT_KERJA_2_LIST,
  DATA_UTAMA_UNIT_KERJA_2_DETAIL,
  DATA_UTAMA_UNIT_KERJA_2_SAVE,
  DATA_UTAMA_UNIT_KERJA_2_DELETE,
  DATA_UTAMA_UNIT_KERJA_2_IMPORT,
  DATA_UTAMA_UNIT_KERJA_2_EXPORT,
  DATA_UTAMA_UNIT_KERJA_3_LIST,
  DATA_UTAMA_UNIT_KERJA_3_DETAIL,
  DATA_UTAMA_UNIT_KERJA_3_SAVE,
  DATA_UTAMA_UNIT_KERJA_3_DELETE,
  DATA_UTAMA_UNIT_KERJA_3_IMPORT,
  DATA_UTAMA_UNIT_KERJA_3_EXPORT,
  DATA_UTAMA_UNIT_KERJA_4_LIST,
  DATA_UTAMA_UNIT_KERJA_4_DETAIL,
  DATA_UTAMA_UNIT_KERJA_4_SAVE,
  DATA_UTAMA_UNIT_KERJA_4_DELETE,
  DATA_UTAMA_UNIT_KERJA_4_IMPORT,
  DATA_UTAMA_UNIT_KERJA_4_EXPORT,
  DATA_UTAMA_KELAS_JABATAN_LIST,
  DATA_UTAMA_KELAS_JABATAN_DETAIL,
  DATA_UTAMA_KELAS_JABATAN_SAVE,
  DATA_UTAMA_KELAS_JABATAN_DELETE,
  DATA_UTAMA_KELAS_JABATAN_ALL,
  DATA_UTAMA_JABATAN_LIST,
  DATA_UTAMA_JABATAN_DETAIL,
  DATA_UTAMA_JABATAN_SAVE,
  DATA_UTAMA_JABATAN_DELETE,
  DATA_UTAMA_JABATAN_ALL,
  DATA_UTAMA_JABATAN_LIST_KATEGORI,
  DATA_UTAMA_JABATAN_KATEGORI,
  DATA_UTAMA_FORMASI_JABATAN_LIST,
  DATA_UTAMA_FORMASI_JABATAN_DETAIL,
  DATA_UTAMA_FORMASI_JABATAN_SAVE,
  DATA_UTAMA_FORMASI_JABATAN_DELETE,
  DATA_UTAMA_FORMASI_JABATAN_PERSEDIAAN,
  DATA_UTAMA_FORMASI_JABATAN_EXPORT,
  DATA_UTAMA_FORMASI_JABATAN_IMPORT,
  DATA_UTAMA_KELOMPOK_JABATAN_LIST,
  DATA_UTAMA_KELOMPOK_JABATAN_DETAIL,
  DATA_UTAMA_KELOMPOK_JABATAN_SAVE,
  DATA_UTAMA_KELOMPOK_JABATAN_DELETE,
  DATA_UTAMA_KELOMPOK_JABATAN_ALL
} from "../constants";

const DataUtamaService = {
  // Golongan
  getGolonganList(data) {
    return BaseInstance.post(DATA_UTAMA_GOLONGAN_LIST, data);
  },
  getGolonganDetail(data) {
    return BaseInstance.post(DATA_UTAMA_GOLONGAN_DETAIL, data);
  },
  saveGolongan(data) {
    return BaseInstance.post(DATA_UTAMA_GOLONGAN_SAVE, data);
  },
  deleteGolongan(params) {
    return BaseInstance.remove(DATA_UTAMA_GOLONGAN_DELETE, { params });
  },
  getGolonganListAll(data) {
    return BaseInstance.query(DATA_UTAMA_GOLONGAN_ALL, data);
  },
  // Kepangkatan
  getKepangkatanList(data) {
    return BaseInstance.post(DATA_UTAMA_KEPANGKATAN_LIST, data);
  },
  getKepangkatanDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_KEPANGKATAN_DETAIL, id);
  },
  saveKepangkatan(data) {
    return BaseInstance.post(DATA_UTAMA_KEPANGKATAN_SAVE, data);
  },
  deleteKepangkatan(params) {
    return BaseInstance.remove(DATA_UTAMA_KEPANGKATAN_DELETE, { params });
  },
  getKepangkatanListByGol(data) {
    return BaseInstance.post(DATA_UTAMA_KEPANGKATAN_LIST_BY_GOL, data);
  },
  // Eselon
  getEselonList(data) {
    return BaseInstance.post(DATA_UTAMA_ESELON_LIST, data);
  },
  getEselonDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_ESELON_DETAIL, id);
  },
  saveEselon(data) {
    return BaseInstance.post(DATA_UTAMA_ESELON_SAVE, data);
  },
  deleteEselon(params) {
    return BaseInstance.remove(DATA_UTAMA_ESELON_DELETE, { params });
  },
  getEselonListAll(data) {
    return BaseInstance.query(DATA_UTAMA_ESELON_ALL, data);
  },
  getEselonByJabatan(id) {
    return BaseInstance.fetch(DATA_UTAMA_ESELON_LIST_BY_JABATAN, id);
  },
  // Pendidikan
  getPendidikanList(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_LIST, data);
  },
  getPendidikanDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_PENDIDIKAN_DETAIL, id);
  },
  savePendidikan(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_SAVE, data);
  },
  deletePendidikan(params) {
    return BaseInstance.remove(DATA_UTAMA_PENDIDIKAN_DELETE, { params });
  },
  // Studi
  getStudiList(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_STUDI_LIST, data);
  },
  getStudiDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_PENDIDIKAN_STUDI_DETAIL, id);
  },
  saveStudi(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_STUDI_SAVE, data);
  },
  deleteStudi(params) {
    return BaseInstance.remove(DATA_UTAMA_PENDIDIKAN_STUDI_DELETE, { params });
  },
  // Pendidikan Level
  getPendidikanLevelList(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_LEVEL_LIST, data);
  },
  getPendidikanLevelDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_PENDIDIKAN_LEVEL_DETAIL, id);
  },
  savePendidikanLevel(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_LEVEL_SAVE, data);
  },
  deletePendidikanLevel(params) {
    return BaseInstance.remove(DATA_UTAMA_PENDIDIKAN_LEVEL_DELETE, { params });
  },
  getPendidikanLevelLOV(params) {
    return BaseInstance.query(DATA_UTAMA_PENDIDIKAN_LEVEL_ALL, { params });
  },
  // Rumpun
  getPendidikanRumpunList(params) {
    return BaseInstance.query(DATA_UTAMA_PENDIDIKAN_RUMPUN_LIST, { params });
  },
  getPendidikanRumpunDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_PENDIDIKAN_RUMPUN_DETAIL, id);
  },
  savePendidikanRumpun(data) {
    return BaseInstance.post(DATA_UTAMA_PENDIDIKAN_RUMPUN_SAVE, data);
  },
  deletePendidikanRumpun(params) {
    return BaseInstance.remove(DATA_UTAMA_PENDIDIKAN_RUMPUN_DELETE, { params });
  },
  getUnitKerjaGroupLOV(params) {
    return BaseInstance.query(DATA_UTAMA_UNIT_KERJA_GROUP_LOV, { params });
  },
  getUnitKerjaAll(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_ALL, data);
  },
  // Unit Utama
  getUnitUtamaList(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_UTAMA_LIST, data);
  },
  getUnitUtamaDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_UNIT_KERJA_UTAMA_DETAIL, id);
  },
  saveUnitUtama(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_UTAMA_SAVE, data);
  },
  deleteUnitUtama(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_UTAMA_DELETE, data);
  },
  importUnitUtama(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_UTAMA_IMPORT, data);
  },
  exportUnitUtama(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_UTAMA_EXPORT, data);
  },
  // Unit Group
  getUnitGroupList(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_GROUP_LIST, data);
  },
  getUnitGroupDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_UNIT_KERJA_GROUP_DETAIL, id);
  },
  saveUnitGroup(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_GROUP_SAVE, data);
  },
  deleteUnitGroup(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_GROUP_DELETE, data);
  },
  importUnitGroup(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_GROUP_IMPORT, data);
  },
  exportUnitGroup(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_GROUP_EXPORT, data);
  },
  // Unit Kerja 2
  getUnitKerja2List(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_2_LIST, data);
  },
  getUnitKerja2Detail(id) {
    return BaseInstance.fetch(DATA_UTAMA_UNIT_KERJA_2_DETAIL, id);
  },
  saveUnitKerja2(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_2_SAVE, data);
  },
  deleteUnitKerja2(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_2_DELETE, data);
  },
  importUnitKerja2(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_2_IMPORT, data);
  },
  exportUnitKerja2(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_2_EXPORT, data);
  },
  // Unit Kerja 3
  getUnitKerja3List(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_3_LIST, data);
  },
  getUnitKerja3Detail(id) {
    return BaseInstance.fetch(DATA_UTAMA_UNIT_KERJA_3_DETAIL, id);
  },
  saveUnitKerja3(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_3_SAVE, data);
  },
  deleteUnitKerja3(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_3_DELETE, data);
  },
  importUnitKerja3(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_3_IMPORT, data);
  },
  exportUnitKerja3(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_3_EXPORT, data);
  },
  // Unit Kerja 4
  getUnitKerja4List(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_4_LIST, data);
  },
  getUnitKerja4Detail(id) {
    return BaseInstance.fetch(DATA_UTAMA_UNIT_KERJA_4_DETAIL, id);
  },
  saveUnitKerja4(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_4_SAVE, data);
  },
  deleteUnitKerja4(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_4_DELETE, data);
  },
  importUnitKerja4(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_4_IMPORT, data);
  },
  exportUnitKerja4(data) {
    return BaseInstance.post(DATA_UTAMA_UNIT_KERJA_4_EXPORT, data);
  },
  // Kelas Jabatan
  getKelasJabatanList(data) {
    return BaseInstance.post(DATA_UTAMA_KELAS_JABATAN_LIST, data);
  },
  getKelasJabatanDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_KELAS_JABATAN_DETAIL, id);
  },
  saveKelasJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_KELAS_JABATAN_SAVE, data);
  },
  deleteKelasJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_KELAS_JABATAN_DELETE, data);
  },
  getKelasJabatanListAll(data) {
    return BaseInstance.query(DATA_UTAMA_KELAS_JABATAN_ALL, data);
  },
  // Jabatan
  getJabatanList(data) {
    return BaseInstance.post(DATA_UTAMA_JABATAN_LIST, data);
  },
  getJabatanDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_JABATAN_DETAIL, id);
  },
  saveJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_JABATAN_SAVE, data);
  },
  deleteJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_JABATAN_DELETE, data);
  },
  getJabatanListAll(data) {
    return BaseInstance.query(DATA_UTAMA_JABATAN_ALL, data);
  },
  getJabatanListKategori(data) {
    return BaseInstance.query(DATA_UTAMA_JABATAN_LIST_KATEGORI, data);
  },
  getJabatanByKategori(data) {
    return BaseInstance.post(DATA_UTAMA_JABATAN_KATEGORI, data);
  },
  // Formasi Jabatan
  getFormasiJabatanList(data) {
    return BaseInstance.post(DATA_UTAMA_FORMASI_JABATAN_LIST, data);
  },
  getFormasiJabatanDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_FORMASI_JABATAN_DETAIL, id);
  },
  saveFormasiJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_FORMASI_JABATAN_SAVE, data);
  },
  deleteFormasiJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_FORMASI_JABATAN_DELETE, data);
  },
  getFormasiJabatanPersediaan(data) {
    return BaseInstance.post(DATA_UTAMA_FORMASI_JABATAN_PERSEDIAAN, data);
  },
  exportFormasiJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_FORMASI_JABATAN_EXPORT, data);
  },
  importFormasiJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_FORMASI_JABATAN_IMPORT, data);
  },
  // Kelompok Jabatan
  getKelompokJabatanList(data) {
    return BaseInstance.post(DATA_UTAMA_KELOMPOK_JABATAN_LIST, data);
  },
  getKelompokJabatanDetail(id) {
    return BaseInstance.fetch(DATA_UTAMA_KELOMPOK_JABATAN_DETAIL, id);
  },
  saveKelompokJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_KELOMPOK_JABATAN_SAVE, data);
  },
  deleteKelompokJabatan(data) {
    return BaseInstance.post(DATA_UTAMA_KELOMPOK_JABATAN_DELETE, data);
  },
  getKelompokJabatanListAll(data) {
    return BaseInstance.query(DATA_UTAMA_KELOMPOK_JABATAN_ALL, data);
  }
};

export default DataUtamaService;
