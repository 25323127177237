<template>
  <div>
    <v-card id="card-custom" class="px-2 py-4 rounded-lg">
      <v-card-actions>
        <p class="headline-color px-2">
          Daftar Program Studi {{ selected.nama_pend }}
        </p>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="getToPage('data-pendidikan-rumpun')"
            >
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
          <span>Daftar Rumpun</span>
        </v-tooltip>
      </v-card-actions>
      <v-divider />
      <v-row class="mx-2">
        <v-col cols="8" xl="8" lg="8" md="12" sm="12" xs="12" class="px-0">
          <v-btn
            text
            class="text-capitalize caption headline-color"
            color="primary"
            @click="
              visible = true;
              isEdit = false;
            "
            >Buat Program Studi<v-icon class="ml-2" color="primary"
              >add_circle</v-icon
            ></v-btn
          >
        </v-col>
        <v-col cols="4" xl="4" lg="4" md="12" sm="12" xs="12" class="px-0">
          <v-text-field
            v-model="search"
            placeholder="Cari"
            outlined
            dense
            hide-details
            prepend-inner-icon="search"
            class="mx-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        id="table-custom"
        :headers="headers"
        :items="studis"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pagination.totalItem"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems
        }"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small color="primary" @click="handleEdit(item)"
            ><v-icon small>edit</v-icon></v-btn
          >
          <v-btn icon small color="primary" @click="handleDelete(item)"
            ><v-icon small>delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Program Studi
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kode</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.kode_studi"
                :disabled="isEdit"
                outlined
                dense
                :rules="[v => !!v || 'Kode harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Program Studi</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.nama_studi"
                outlined
                dense
                :rules="[v => !!v || 'Program studi harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Rumpun</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-autocomplete
                v-model="form.kode_rumpun"
                :loading="loadingRumpun"
                :items="rumpuns"
                outlined
                dense
                item-text="nama_rumpun"
                item-value="kode_rumpun"
                :rules="[v => !!v || 'Rumpun harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Gelar</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.gelar"
                outlined
                dense
                :rules="[v => !!v || 'Gelar harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      isEdit: false,
      loading: false,
      loadingRumpun: false,
      formLoading: false,
      rumpuns: [],
      search: null,
      selected: {
        gelar_pend: null,
        kode_pend: null,
        nama_pend: null,
        pend_level: null,
        pend_level_id: null
      },
      headers: [
        {
          text: "Kode",
          value: "kode_studi",
          sortable: false,
          align: "center"
        },
        {
          text: "Program Studi",
          value: "nama_studi",
          sortable: false,
          align: "center"
        },
        {
          text: "Rumpun",
          value: "nama_rumpun",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      studis: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["kode_studi"],
        sortDesc: [false]
      },
      dayDiffTry: 0,
      dayDiff: 0,
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        kode_studi: null,
        kode_pend: null,
        nama_studi: null,
        kode_rumpun: null,
        gelar: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        if (this.selected.kode_pend != null) {
          this.getStudiList();
        }
      },
      deep: true
    },
    selected: {
      handler() {
        this.getStudiList();
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getStudiList);
    },
    visible(val) {
      if (!val) {
        this.dayDiff = 0;
        this.dayDiffTry = 0;
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search].join();
    }
  },
  methods: {
    onSelect(item) {
      this.$_.merge(this.selected, item);
    },
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.getDetailStudi(item.kode_studi);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              kode_studi: item.kode_studi
            };
            this.deleteStudi(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("kode_studi", this.form.kode_studi);
        formData.append("kode_pend", this.selected.kode_pend);
        formData.append("nama_studi", this.form.nama_studi);
        formData.append("kode_rumpun", this.form.kode_rumpun);
        formData.append("gelar", this.form.gelar);
        this.saveStudi(formData);
      }
    },
    // Service
    async getRumpunList() {
      try {
        this.loadingRumpun = true;
        await DataUtamaService.getPendidikanRumpunList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.rumpuns = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingRumpun = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getStudiList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getStudiList({
          filter: {
            search: this.search,
            kode_pend: this.selected?.kode_pend
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let studis = list;
              studis.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.studis = studis;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveStudi(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.saveStudi(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getStudiList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailStudi(data) {
      try {
        this.loading = true;
        await DataUtamaService.getStudiDetail(data)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.$_.merge(this.form, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteStudi(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deleteStudi(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getStudiList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getRumpunList();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
